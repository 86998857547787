.App {
  text-align: center;
  margin: 2rem;
}

@font-face {
  font-family: "Visitor TT1 BRK";
  src: url("fonts/VisitorTT1BRK.woff2") format("woff2"),
    url("fonts/VisitorTT1BRK.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0px;
}

h1 {
  font-size: 36px;
  color: #1f1f1f;
  font-family: "Visitor TT1 BRK";
}

h2 {
  color: #fff14b;
  font-family: "Visitor TT1 BRK";
  text-decoration: underline;
}

text {
  color: #4f4f4f;
  line-height: 1.5;
  font-family: "Visitor TT1 BRK";
  margin-top: 0.4rem;
}

.m-5 {
  margin-bottom: -5px;
}

.mt8 {
  margin-top: 8px;
}

.mt80 {
  padding-top: 80px;
}


.mt100 {
  margin-top: 100px !important;
}

.link {
  /* text-decoration: #fff14b underline; */
  color: #59b8b8;
}

.link:hover {
  color: #68e0cf;
  text-decoration: #68e0cf underline;
}

.nav {
  color: #fff;
  background-color: #1f1f1f;
  font-size: 1em;
  text-transform: uppercase;
  font-family: "Source Code Pro";
  line-height: 80px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 33%;
  flex-wrap: wrap;
  position: fixed;
  width: 100%;
  z-index: 2;
}

.small-screen {
  display: none;
}

.desktop-view {
  display: block;
}

.mobile-view {
  display: none;
  background-color: #1f1f1f;
  height: 100vh;
}

/* .menu-opened {
  display: block;
}

.menu {
  display: block;
} */

.navlink:hover {
  color: #68e0cf;
}

.line:hover,
.line1:hover,
.line2:hover,
.line3:hover {
  stroke: #68e0cf;
}

.nav > ul,
.nav > li {
  list-style: none;
  float: left;
  letter-spacing: 1.5px;
  margin: 0px 10px 0px 10px;
  font-family: "Source Code Pro";
  float: left;
  padding: 0px 1em;
}

.burger {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
}

.menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  outline: none;
}
.line {
  fill: none;
  stroke: #fff;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

.container {
  margin: auto;
  width: 50%;
  position: relative;
}

.container3 {
  margin: auto;
  width: 50%;
  padding: 50px;
  background-color: #ffe791;
  position: relative;
}

.img {
  width: 800px;
}

.img2 {
  width: 200px;
}

/* .image {
  right: 0px;
  display: flex;
  float: left;
} */

.band {
  width: 100%;
  left: 0px;
  position: absolute;
  background-color: #ffffff;
  border: 10px solid #fff14b;
  border-left-width: 0px;
  border-right-width: 0px;
  font-size: 30px;
  padding: 0px 0px 0px 0px;
  font-family: "Visitor TT1 BRK";
}

svg {
  height: 40px;
  margin: 15px 0;
  color: white;
  margin: 20px 10px 20px 10px;
}

svg:hover {
  color: #fff14b;
}

p {
  align-self: center;
  color: inherit;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin: 0px;
  font-weight: normal;
  font-family: "Source Code Pro", sans-serif;
  font-style: normal;
}

a {
  text-decoration: none;
}

.text-container {
  display: block;
  margin: auto;
  max-width: 900px;
  font-family: "Source Code Pro";
  text-transform: initial;
  color: #1f1f1f;
  justify-content: center;
  align-items: left;
  background: #eaf6ff;
  padding: 3rem;
}

.text-container > img {
  margin-bottom: -10px;
  margin-top: 20px;
  margin-right: 1%;
}

.text-container2 {
  margin: auto;
  max-width: 900px;
  font-family: "Source Code Pro";
  text-transform: initial;
  color: #1f1f1f;
  justify-content: center;
  align-items: left;
  background: #eaf6ff;
  padding: 3rem;
}

.footer {
  font-family: "Source Code Pro", sans-serif;
  margin: auto;
  text-align: center;
  width: 100%;
  height: 130px;
  background-color: #1f1f1f;
  position: sticky;
  bottom: 0px;
  left: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  color: #ffffff;
  font-size: 18px !important;
}

.footer > p {
  color: #ffffff !important;
  font-size: 12px;
}

@media only screen and (max-width: 1024px) {
  :root {
    font-size: 12px !important;
  }

  .img {
    width: 100%;
  }
  .container {
    width: 100%;
  }

  .section {
    padding: 4rem 0rem 0rem 0rem !important;
  }

  .text-container {
    width: 100%;
  }

  .text-container > img {
    margin-bottom: -15px;
    margin-top: 20px;
    margin-right: 0% !important;
  }

  .text-container2 {
    width: 100%;
  }

  .small-screen {
    display: block;
  }

  .desktop-view {
    display: none;
  }

  .nav {
    flex-direction: unset !important;
    align-items: left !important;
    font-size: 2em;
  }
}

.icon-list {
  display: flex;
  margin: 5% auto;
  text-align: center;
  flex-wrap: wrap;
}

.icon-item {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 33%;
}

@media (max-width: 600px) {
  .icon-list {
    flex-direction: column;
  }

  .icon-item {
    width: 90%;
  }
}

.icon-item > h2 {
  color: #1f1f1f;
  text-decoration: #fff14b underline;
}

/* timeline */

ul,
li {
  float: left;
  list-style: none;
  padding: 0 1em;
}

.container2 {
  display: flex;
  margin: 0px;
  justify-content: center;
  align-items: center;
  padding: 8rem 0;
}

.g1 {
  background: linear-gradient(180deg, #46475c, #68e0cf);
}

.g2 {
  background: linear-gradient(180deg, #68e0cf, #46475c);
}

.wrapper {
  background: #eaf6ff;
  padding: 3rem;
  border-radius: 0px;
  max-width: 900px;
}

.sessions {
  margin-top: 2rem;
  border-radius: 12px;
  position: relative;
}

.sessions > li {
  padding-bottom: 1.5rem;
  border-left: 3px solid #fff;
  position: relative;
  padding-left: 20px;
  margin-left: 10px;
  font-family: "Source Code Pro", sans-serif;
}

.sessions > li:last-child {
  border: 0px;
  padding-bottom: 0;
}

.sessions > li:before {
  content: "";
  width: 15px;
  height: 15px;
  background: #fff14b;
  border: 0px solid #4e5ed3;
  box-shadow: 3px 3px 0px #000000;
  box-shadow: 3px 3px 0px #000000;
  border-radius: 0%;
  position: absolute;
  left: -10px;
  top: 0px;
}

.time {
  color: #1f1f1f;
  font-family: "Visitor TT1 BRK";
  font-size: 36px;
  line-height: 22px;
}

p {
  color: #4f4f4f;
  line-height: 1.5;
  margin-top: 0.4rem;
}

.section {
  display: flex;
  margin: 0px;
  justify-content: center;
  align-items: center;
  background: "coming-soon-bg.gif";
  padding: 4rem 6rem;
}

/* accordion */

:root {
  font-size: 16px;
}
*,
::before,
::after {
  box-sizing: inherit;
}
html {
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  padding: 0;
}
body {
  line-height: 1.25;
}
input,
button {
  display: inline-block;
  margin: 0;
  padding: 0.3125em 0.625em;
  font: inherit;
}
a {
  color: currentColor;
}

.accordion {
  margin-bottom: 20px;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

.accordion > p {
  font-family: "Source Code Pro", sans-serif;
}

.accordion__button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 15px 20px;
  width: 100%;
  font-family: "Visitor TT1 BRK";
  text-transform: uppercase;
  font-size: 1.5em;
  font-weight: 500;
  color: #fff;
  text-align: left;
  background: rgb(87 184 184);
  border: 0;
  border-radius: 0;
  transition: background 0.3s ease;
  cursor: pointer;
}

.accordion__button:after {
  content: "\002B";
  display: block;
  margin-left: 15px;
  font: inherit;
  color: #fff;
}

.accordion__button:hover,
.accordion__button.is-active {
  background: #157;
}

.accordion__button.is-active:after {
  content: "\2212";
}

.accordion__button:focus {
  background: #59b;
  outline: none;
}

.accordion__body {
  background: #fff;
  display: block;
  transition: max-height 0.3s ease;
  max-height: 0px;
  overflow: hidden;
}

.accordion__body.is-active {
  max-height: 300px;
  overflow: scroll;
}

  /* editor */
.tv-frame {
  height: 97vh;
  width: 97vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

#editor {
  padding: 2vmax;
  background: #222;
  color: white;
  box-shadow: 0 13px 8px 3px rgba(40,40,40,0.3);
}

#editor canvas {
  margin-bottom: 1vmax;
}

#editor button {
  background: white;
  color: #222;
  border: 0;
  padding: .3em 1em;
}
